@import "variables";
@import "fonts";

button.btn {
  cursor: pointer;
}

.rd-navbar-top-panel {
  .unit.unit-horizontal {
    .unit-body {
      line-height: 46px;
    }
  }
}

.parallax-container {
  background-position: left center;
}

.breadcrumb-modern {
  background-image: url("../img/backgrounds/background-breadcrumbs-01-1920x345-new.jpg");
  background-position: right;
}

.thumbnail-custom:before, .thumbnail-custom .thumbnail-custom-caption {
  opacity: 0;
}

@import "media";